<template>
    <div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow-y: auto;" v-if="queriedProjects.length">
        <NewSiteDialog :start="newSiteBoolean" @close="newSiteBoolean = false"/>
        <a-modal :title="dialog.title" :visible="dialog.show" @cancel="closeDialog" @ok="handleDialogOK" >
            <a-form-model :rules="rules" :model="dialog" ref="dialog">
                <a-form-model-item v-if="dialog.show" label="Project Name" prop="value">
                    <a-input v-model="dialog.value" :placeholder="dialog.placeholder" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
		<a-modal
			v-model="exportModal.visible"
			width="500px"
			:footer="null"
			@cancel="closeExportModal"
			:centered="true"
		>
			<BHLoading :show="loading" />
			<div class="mb-2">
				<h5 class="mb-4" v-text="'Export to Existing Project'" />

				<p class="mb-2" style="color: #40454c; font-size: 14px">
					Select a Project<span style="color: var(--danger)"> *</span>
				</p>
				<a-select
					size="large"
					v-model="exportModal.instance"
					class="w-full"
					placeholder="Select a Project"
					show-search :filter-option="filterOption"
				>
					<a-select-option v-for="(inst,instI) in instances" :value="inst.id" :key="inst+instI">{{inst.name}}</a-select-option>
				</a-select>
			</div>
			<div class="dF jE mt-5">
				<a-button class="cancel-button" @click="closeExportModal">CANCEL</a-button>
				<a-button class="ml-3" type="primary" @click="confirmExport()">
					EXPORT
				</a-button>
			</div>
		</a-modal>

        <div class="f1 rounded">
            <div class="project-wrapper">
                <ProjectCard v-for="prj in queriedProjects" :key="prj.id" :project="prj" @delete="deletePRJ(prj)" @rename="renamePRJ(prj)" @preview="previewPRJ(prj)" @duplicate="duplicatePRJ(prj)" @edit="editProject(prj)" @export="exportProject(prj)" />
            </div>
        </div>

    </div>
    <div class="dF fC f1 px-4 pb-4 " style="background:var(--light-bg-gray)" v-else>
        <NewSiteDialog :start="newSiteBoolean" @close="newSiteBoolean = false"/>
        <EmptyApp :img="require('@/assets/empty.svg')" :text="emptyText"  />
    </div>
</template>

<script>
    import {formatDate} from 'bh-mod'
    import NewSiteDialog from '@/components/index/NewSiteDialog'
    import ProjectCard from '@/components/index/ProjectCard'
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import axios from 'axios'

    export default {
        components:{
            BHLoading,EmptyApp,NewSiteDialog,ProjectCard,
        },
        data() {
            return {
				loading:false,
                dialog:{
                    value:'',
                    show:false,
                    placeholder:'',
                    title:'',
                    type:'',
                    id:'',
                },
				exportModal:{
					visible:false,
					template:{},
					instance:''
				},
                newSiteBoolean:false,
                addSiteBoolean:false,
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1',
                rules:{
                    value: [
                        { min: 2, message: 'Project name should be longer than 2 characters!', trigger: 'blur' },
                        { required:true, message: 'Please enter a project name', trigger: 'blur' },
                    ],
                },
            }
        },
        watch:{

            'preview.show'(val){
                console.log('SHOWING PREVIEW', this.preview)

            },
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
			fontList(){
                let fonts = this.$store.state.appData.fontList
                let obj = {}
                fonts.forEach(item => {

                    let boldWeights = []

                    let hasBold = false
                    let boldVariant = 0

                    let italicWeights = []
                    let hasItalics = false
                    let italicVariant = 0

                    let boldItalicWeights = []
                    let hasBoldItalics = false
                    let boldItalicVariant = 0



                    item.weights = item.variants.map( wght => {

                        if (wght === 'regular') return '400'
                        else if (wght === 'italic') return '400i'

                        if (wght.indexOf('italic') > 0) return wght.replace('italic','i')

                    })
                    item.weights.forEach( weight => {

                        let w = parseInt(weight)
                        if (isNaN(w)) return

                        if (weight.includes('i')){
                            hasItalics = true
                            italicWeights.push(w)
                            if (w > 250 && w < 600){
                                hasItalics = true
                                if (w === 400){

                                }

                            } else if (w > 600) {

                            }

                        } else if (w > 650) {

                            if (w == 700) {

                                hasBold = true
                                boldVariant = 700

                            } else if (w == 800) {

                                hasBold = true
                                boldVariant = 800

                            } else if (w == 900) {

                                hasBold = true
                                boldVariant = 900

                            }

                        }

                    })


                    item.category = item.category === 'handwriting' ? 'cursive' : item.category
                    item.hasBold = hasBold
                    item.boldVariant = boldVariant
                    obj[item.family] = item

                })
                return obj
            },
            emptyText(){
                let filter = this.$store.state.appData.filter
                let lookup = {
                    web:'Microsites',
                    landing:'Landing Pages',
                    published:'published projects'
                }
                return `You don't have any ${lookup[filter] || 'projects'}`
            },
            queriedProjects(){
                let filter = this.$store.state.appData.filter
                if (!filter) return this.projects
                if (filter === 'published') return this.projects.filter(prj => prj.published || (prj.domain && prj.url))
                return this.projects.filter(prj => prj.type === this.$store.state.appData.filter)
            },
            projects(){
                return Object.values(this.$store.state.appData.projects)
            },
			instances(){
				return this.$store.state.instances.filter(x => x.id != this.instance.id).sort((a,b) => {
					let nameA = a.name.toUpperCase()
					let nameB = b.name.toUpperCase()
					if (nameA < nameB) return -1
					if (nameA > nameB) return 1
					return 0
				})
			},
            instance(){
                return this.$store.state.instance
            },
			ctnURL() {
				return this.$store.state.appData.ctnURL;
			},
            loadingPage:{
                get(){
                    return this.$store.state.appData.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
			closeExportModal(){
				this.exportModal = {
					visible:false,
					template:{},
					instance:''
				}
			},
            async previewPRJ(prj){
                this.$store.commit('LOAD',true)
				console.log('PRJ', prj);
				try {
					let { data: project } = await this.$api.get(`/projects/:instance/${prj.id}`)
					let indexid = ''
					console.log('PROJECT.', project)

					if (prj.type != 'static' && !this.modelsData){
						let models = await fetch(`https://api.bildhive.${this.$tld}/v2/models?token=${this.instance.id}&children=1`).then(x => x.json())
						this.modelsData = models
					}

					let bildURL = `${this.ctnURL}/bildsite`

					let apiKey = ''
					if (this.instance && this.instance.api && this.instance.api.key){
						apiKey = this.instance.api.key
					}

					let { data: saved } = await axios.post(bildURL, {
						renderAll:true,
						datas:{
							_modelsData:this.modelsData,
							model:this.$store.getters.previewModel
						},
						iSlug:this.instance.slug,
						pageReq: '/',
						project: project,
						apiKey: apiKey
					})

					if (!saved.id || !saved.url){

						return this.$message.error(
							"There was a problem while saving Web site preview"
						);
					}

					let slug = ''

					let theURL = `${saved.url}/${slug}`;
					this.$store.commit("LOAD", false);
					window.open(theURL)

					this.$store.commit('LOAD', false)


				} catch(err){
                    console.error('there was a problem trying to preview\n',err)
                    this.$store.commit('LOAD',false)
                    this.$message.error('There was a problem while previewing your website. Please try again.')
                }
            },
            confirmDeleteProject(id){
                this.$api.delete(`/projects/:instance/${id}`).then( () => {
                    this.$store.commit('SET_PROP', {where:['projects',id],del:true})
                }).catch( () => {
                    this.$message.error('There was a problem deleting. Please try again')
                })
            },
            deletePRJ(prj){
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete projects')
                }
                let self = this
                this.$confirm({
                    title: 'Delete Project?',
                    content: h => <div>Are you sure want to delete this project? <br/><strong>This cannot be undone!</strong></div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk(){
                        self.confirmDeleteProject(prj.id)
                    },
                    onCancel(){
                        console.log('Cancel')
                    }
                })
            },
            closeDialog(){
                this.dialog = {
                    value:'',
                    show:false,
                    placeholder:'',
                    type:'',
                    id:'',
                }
            },
            handleDialogOK(){
                console.log('DIALOG', this.dialog)
                this.$refs.dialog.validate( res => {
                    console.log('RES', res)
                    if (this.dialog && this.dialog.cb){
                        this.dialog.cb()
                    } else {
                        console.log('ERROR', res)
                    }
                })
            },
            renameConfirm(){
                let name = this.dialog.value
                this.$api.put(`/projects/:instance/${this.dialog.id}`, {name})
                this.$store.commit('SET_PROP', {where:['projects',this.dialog.id,'name'],what:name})
                this.closeDialog()
            },
            renamePRJ(prj){

                if (this.$p === 10){
                    return this.$message.error('You do not have permission to rename projects')
                }

                console.log('WANT TO RENAME')
                this.dialog.value=prj.name
                this.dialog.placeholder="Enter Project Name..."
                this.dialog.show = true
                this.dialog.title = `Rename Project`
                this.dialog.type = 'rename'
                this.dialog.id = prj.id
                this.dialog.cb = this.renameConfirm

            },
            async confirmDuplicate() {
				try {
					this.$store.commit('LOAD', true)
					let { data: project } = await this.$api.get(`/projects/:instance/${this.dialog.id}`)

					project.name = this.dialog.value
					delete project.id
					delete project._id

					if (project.headers) project.headers = project.headers.map(({ id, _id, ...obj }) => obj)
					if (project.footers) project.footers = project.footers.map(({ id, _id, ...obj }) => obj)

					for (const key in project.pages) {
						if (project.pages.hasOwnProperty(key)) {
							const page = project.pages[key];

							delete page.id
							delete page._id

							if (page.header) delete page.header.id, delete page.header._id
							if (page.footer) delete page.footer.id, delete page.footer._id
							if (page.sections) page.sections = page.sections.map(({ id, _id, ...obj }) => obj)
						}
					}

					if (project.domain) delete project.domain
					if (project.url) delete project.url

					this.$api.post('projects/:instance', project)
						.then(({ data: saved }) => {
							this.$router.push(`/edit/${saved.id}`)
							this.$message.success('Duplicated project: ', this.dialog.value)
						}).catch(err => {
							this.$message.error('Error while duplicating project: ', this.dialog.value)
						}).finally(() => {
							this.closeDialog()
							this.$store.commit('LOAD', false)
						})
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}
			},
			filterOption(input, option) {
				return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},

			async confirmExport() {
				if (this.exportModal.instance == '') {
					return this.$message.error('Please select an instance')
				}
				try {
					this.loading = true
					let { data: project } = await this.$api.get(`/projects/:instance/${this.exportModal.template.id}`)

					project.name = this.exportModal.template.name + ' COPY'
					delete project.id
					delete project._id

					if (project.headers) project.headers = project.headers.map(({ id, _id, ...obj }) => obj)
					if (project.footers) project.footers = project.footers.map(({ id, _id, ...obj }) => obj)

					for (const key in project.pages) {
						if (project.pages.hasOwnProperty(key)) {
							const page = project.pages[key];

							delete page.id
							delete page._id

							if (page.header) delete page.header.id, delete page.header._id
							if (page.footer) delete page.footer.id, delete page.footer._id
							if (page.sections) page.sections = page.sections.map(({ id, _id, ...obj }) => obj)
						}
					}

					if (project.domain) delete project.domain
					if (project.url) delete project.url

					this.$api.post(`projects/${this.exportModal.instance}`, project)
						.then(({ data: saved }) => {
							this.$message.success(`Exported project: ${this.exportModal.template.name}`)
							this.loading = false;
							this.closeExportModal()
						}).catch(err => {
							this.$message.error('Error while duplicating project: ')
							this.loading = false;
						}).finally(() => {
							this.loading = false;
							this.closeExportModal()
						})
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}
			},
            duplicatePRJ(prj){
                console.log('DUPLICATING PROJECT...', prj)
                if (this.$p === 10){
                    return this.$message.error('You do not have permission to duplicate projects')
                }
                this.dialog.value = prj.name + ' COPY'
                this.dialog.placeholder=`Enter Project name...`
                this.dialog.show = true
                this.dialog.id = prj.id
                this.dialog.title = `Duplicate Project`
                this.dialog.type = 'duplicate'
                this.dialog.cb = this.confirmDuplicate

            },
            exportProject(prj){
				console.log('EXPORTING PROJECT...', prj)
                this.exportModal = {
					visible: true,
					template: prj,
					instance:''
				}
			},
            editProject(draft){
                console.log('EDIT BROADCAST', draft)

                this.$router.push(`/edit/${draft.id}`)
            },
            formatDate,
			generateFont(){

                if (this.fontList && Object.values(this.fontList).length) return

                axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8').then( ({data}) => {
                    this.$store.commit('SET_PROP', {where:['fontList'],what:data.items})
                })

            },
        },
        mounted(){
            this.newSiteBoolean = false
			this.$api.get('/instances').then(({data}) => {
				data = data.filter(x => x.role && x.role.name === 'bh_admin').map(x => {
					return {
						id: x.instance.id,
						name: x.instance.name
					}
				})
				console.log('DATA COMING BACK FROM INSTANCES', data)
				this.$store.commit('SET_INSTANCES', data)
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        created() {
			this.generateFont()
            this.$store.commit('PAGE_BUTTONS', [
                {
                    label:'CREATE SITE',
                    ant:'plus',
                    callback: () => {
                        if (this.$p === 10){
                            return this.$message.error('You do not have permission to create sites')
                        }
                        this.newSiteBoolean = true
                    }
                }
            ])

        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>

    .project-wrapper{

        @media screen and (max-width:768px){
            .project-card{
                &+ .project-card{
                    margin-top:20px;
                }
            }
        }
        @media screen and (min-width:768px){
            display:grid;
            grid-template-columns: repeat(1, minmax(100px,1fr));
            grid-template-rows: repeat(auto,auto-fill);
            gap:2ch;
            grid-template-columns: repeat(2, minmax(100px,1fr));
            gap:2ch;
        }
        @media screen and (min-width:1167px){
            grid-template-columns: repeat(3, minmax(100px,1fr));
            gap:2ch;
        }
        @media screen and (min-width:1300px){
            grid-template-columns: repeat(3, minmax(100px,1fr));
            gap:2ch;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            max-width: calc(100% - 35px);
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
		color: var(--orange) !important;
        background-color: var(--light-gray);
	}
    .popoverContent{
        height: 35px;
        width: 160px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .email-quick-preview{
        width:100%;
        max-width:700px;
        min-height:500px;
        max-height:calc(100vh - 200px);
    }



</style>

<style lang="scss">
    .broadcast-icon{
        fill: #9ea0a5;
    }

    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .tableStyle .ant-table-thead > tr > th {
        background-color: white;
    }
    .tableStyle .ant-table-pagination.ant-pagination{
        margin: 16px 16px;
    }
</style>
